.heading-h3 {
  font-size: 1.75rem !important;
  font-weight: 300 !important;
  margin-top: 2rem !important;
  margin-bottom: 2.5rem !important;
}

.heading-h4 {
  font-size: 1.5rem !important;
  font-weight: 400 !important;
  margin-bottom: 1.5rem !important;
  margin-top: 0 !important;
}

.heading-h5 {
  font-size: 1.25rem !important;
  font-weight: 900 !important;
  margin-bottom: 1.5rem !important;
}

.heading-h6 {
  font-size: 1rem !important;
  font-weight: 500 !important;
  margin-bottom: 0.5rem !important;
  margin-top: 0rem !important;
}
