/* The checkbox-label */
.checkbox-input-label {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 0px;
}

/* Hide the browser's default checkbox */
.checkbox-input-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark-input {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 1px solid #e9e9e9;
}

/* On mouse-over, add a grey background color */
.checkbox-input-label:hover input~.checkmark-input {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-input-label input:checked~.checkmark-input {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark-input:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-input-label input:checked~.checkmark-input:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-input-label .checkmark-input:after {
  left: 8px;
  top: 3px;
  width: 8px;
  height: 14px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
