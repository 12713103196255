/* These <html> and <body> rules are necessary to preserve
 * the Bootstrap styles that NWX was designed against,
 * now that Bolt Typography is included.
 * Remove once the whole app is new-Bolt-friendly.
 */
html {
  font-size: 12px !important;
  @media (min-width: 576px) {
    font-size: 14px !important;
  }
  @media (min-width: 768px) {
    font-size: 16px !important;
  }
}
body {
  font-size: inherit !important;
}

.disclaimer {
  margin-top: 1.5rem;
  font-size: 1rem;
  line-height: 1.4rem;
}

.nw-text-sm {
  font-size: 0.85em;
  line-height: 1.4;
}

.nw-text-xs {
  margin-bottom: 26px;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility;
}
