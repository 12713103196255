/* You can add global styles to this file, and also import other style files */
@import './scss/containers';
@import './scss/forms';
@import './scss/text';
@import './scss/utilities';
@import './scss/colors';
@import '../node_modules/bootstrap/scss/bootstrap';
@import './scss/custom-accordion';
@import './scss/custom-checkbox';
@import './scss/telematics';
@import './scss/heading';
@import './scss/_nw_custom_overrides';

@font-face {
  font-family: 'Gotham Book';
  src: url('./assets/fonts/gotham-book/gotham-book.eot');
  /* IE9 Compat Modes */
  src: url('./assets/fonts/gotham-book/gotham-book.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/gotham-book/gotham-book.otf')
      format('opentype'),
    /* Open Type Font */ url('./assets/fonts/gotham-book/gotham-book.svg')
      format('svg'),
    /* Legacy iOS */ url('./assets/fonts/gotham-book/gotham-book.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/gotham-book/gotham-book.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/gotham-book/gotham-book.woff2')
      format('woff2');
  /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Bold';
  src: url('./assets/fonts/gotham-bold/gotham-bold.eot');
  /* IE9 Compat Modes */
  src: url('./assets/fonts/gotham-bold/gotham-bold.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/gotham-bold/gotham-bold.otf')
      format('opentype'),
    /* Open Type Font */ url('./assets/fonts/gotham-bold/gotham-bold.svg')
      format('svg'),
    /* Legacy iOS */ url('./assets/fonts/gotham-bold/gotham-bold.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/gotham-bold/gotham-bold.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/gotham-bold/gotham-bold.woff2')
      format('woff2');
  /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Light';
  src: url('./assets/fonts/gotham-light/gotham-light.eot');
  /* IE9 Compat Modes */
  src: url('./assets/fonts/gotham-light/gotham-light.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/gotham-light/gotham-light.otf')
      format('opentype'),
    /* Open Type Font */ url('./assets/fonts/gotham-light/gotham-light.svg')
      format('svg'),
    /* Legacy iOS */ url('./assets/fonts/gotham-light/gotham-light.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/gotham-light/gotham-light.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/gotham-light/gotham-light.woff2')
      format('woff2');
  /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

.alert {
  display: grid;
  grid-template-columns: 20px auto;
  grid-column-gap: 10px;
  align-items: center;
  margin-top: 0.25rem;
  margin-bottom: 0px;
  padding: 8px 10px;

  span {
    font-family: 'Proxima-Nova', Arial, sans-serif, Helvetica;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5rem;
  }

  &.header {
    padding: 1rem;
  }
}

.icon {
  display: inline-block;
  height: 20px;
  width: 20px;
  min-height: 20px;
  min-width: 20px;
  border: none;
  justify-self: center;
  background-repeat: no-repeat;

  &.warn {
    background-image: url('./assets/images/alert.svg');
    padding: 0;
    margin: 0;
  }

  &.checkmark {
    background-image: url('./assets/images/checkmark.svg');
  }

  &.error {
    background-image: url('./assets/images/error.svg');
  }

  &.info {
    background-image: url('./assets/images/info.svg');
  }

  &.alert_blue {
    background-image: url('./assets/images/alert_blue.svg');
  }

  &.left-arrow {
    background-image: url('./assets/images/nav-left.svg');
  }

  &.right-arrow {
    background-image: url('./assets/images/nav-right.svg');
  }

  &.close {
    background-image: url('./assets/images/close.svg');
  }

  &.questionmark {
    background-image: url('./assets//images//questionmark.svg');
  }

  &.notification {
    background-image: url('./assets/images/notification-icon.svg');
  }
}

.modal-dialog {
  margin: 0.5rem auto;
}

.id-card-icon {
  box-sizing: content-box;
  height: 45px;
  width: 50px;
}

.nw-footer-link a {
  color: #fff !important;
}