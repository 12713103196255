.nw-container {
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1024px;
    width: 100%;
    margin: 0 auto;
}

.nw-inner-bun {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}
