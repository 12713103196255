.btn-default {
  background-color: #f2f2f2;
  border-color: #ccc;
  box-shadow: none;
  color: #326295;
  input {
    display: none;
  }
}
.btn-default:hover {
  color: #212529;
  background-color: #dfdfdf;
  border-color: #b3b3b3;
}
.activetoggle {
  color: #fff;
  background-color: #404040;
  border-color: #404040;
  outline: none;
}
.activetoggle:hover {
  color: #fff;
  background-color: #404040;
  border-color: #404040;
  outline: none;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  color: #888;
  background: #fff;
  border-color: #d8d8d8;
}
label {
  font-weight: 700;
  max-width: 100%;
  margin-bottom: 0.25rem;
}
.text-right {
  text-align: right !important;
}
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  &:hover {
    color: #000;
    text-decoration: none;
  }
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

.form-check-label {
  font-weight: 300;
}
.telematics-check {
  label {
    font-size: 16px !important;
    margin-left: 2.5rem !important;
  }
  input {
    position: absolute !important;
  }
}

.font-weight-bold {
  font-weight: 700 !important;
}

.float-right {
  float: right !important;
}

.alert svg {
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 1rem;
}
.alert-info svg path {
  fill: #17a2b8;
}

@media (max-width: 575px) {
  .col-xs-1 {
    flex: 0 0 auto;
    max-width: 8.33%;
  }
  .col-xs-2 {
    flex: 0 0 auto;
    max-width: 16.66666667%;
  }
  .col-xs-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xs-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xs-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xs-7 {
    flex: 0 0 58.33%;
    max-width: 58.33%;
  }
  .col-xs-8 {
    flex: 0 0 66.66%;
    max-width: 66.66%;
  }
  .col-xs-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xs-10 {
    flex: 0 0 83.33%;
    max-width: 83.33%;
  }
  .col-xs-11 {
    flex: 0 0 91.66%;
    max-width: 91.66%;
  }
  .col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 576px) {
  .col-sm-1 {
    flex: 0 0 auto;
    max-width: 8.33%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33%;
    max-width: 58.33%;
  }
  .col-sm-8 {
    flex: 0 0 66.66%;
    max-width: 66.66%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33%;
    max-width: 83.33%;
  }
  .col-sm-11 {
    flex: 0 0 91.66%;
    max-width: 91.66%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .col-md-1 {
    flex: 0 0 auto;
    max-width: 8.33%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33%;
    max-width: 58.33%;
  }
  .col-md-8 {
    flex: 0 0 66.66%;
    max-width: 66.66%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33%;
    max-width: 83.33%;
  }
  .col-md-11 {
    flex: 0 0 91.66%;
    max-width: 91.66%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .col-lg-1 {
    flex: 0 0 auto;
    max-width: 8.33%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33%;
    max-width: 58.33%;
  }
  .col-lg-8 {
    flex: 0 0 66.66%;
    max-width: 66.66%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33%;
    max-width: 83.33%;
  }
  .col-lg-11 {
    flex: 0 0 91.66%;
    max-width: 91.66%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.btn-check + .btn:hover {
  color: #326295;
  background-color: #dfdfdf;
  border-color: #b3b3b3;
}

.btn-group-toggle > .btn-check {
  &:focus-within + .btn-default {
    outline: 1px dotted #aaccee !important;
    outline-offset: -4px !important;
  }
  &:focus + .btn-default {
    color: #212529 !important;
    background-color: #dfdfdf !important;
    border-color: #b3b3b3 !important;
    box-shadow: 0 0 0 0.2rem rgba(178, 179, 180, 0.5) !important;
  }
}

.btn-group > .btn-check:checked + .btn {
  z-index: 1;
  background: #404040 !important;
  border-color: #404040 !important;
  color: white !important;
  box-shadow: none !important;
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: 0 !important;
  z-index: 1;
}

.btn-default:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #404040;
  border-color: #404040;
}

.btn:hover {
  cursor: pointer;
}
.btn:disabled {
  cursor: not-allowed !important;
  pointer-events: all !important;
}
