.tm-icon {
  box-sizing: content-box;
  height: 60px;
}

.tm-connected-car-icon {
  box-sizing: content-box;
  height: 60px;
  width: 65px;
  margin-bottom: 10px;
}

.tm-content-container {
  margin-bottom: 2em;
}

.tm-vehicle-container {
  border: 1px solid #9c96ab;
  padding: 10px 25px 10px 25px;
  margin: 5px;
}

@mixin vehicle-details-color {
  background: dimgray;

  .smartmiles & {
    background: #947dad;
  }

  .smartride & {
    background: #81bec5;
  }

  .connected-car & {
    background: #003b5c;
  }

  .prequalified & {
    background: #003b5c;
  }
}

.tm-vehicle-details {
  @include vehicle-details-color();
  color: #fff !important;
  h3 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
}

.tm-vehicle-premium {
  font-weight: 500 !important;
}

.tm-graphic-container {
  color: #40395f;
  font-weight: bold;
}

.tm-cost-graphic-container {
  color: white;
  font-weight: bold;
  background: #40395f;
  max-height: 60px;
  padding-top: 5px;
  line-height: initial;
}

.tm-btn {
  white-space: normal !important;
  color: #000;
  background-color: white;
}

.tm-btn:hover {
  background-color: #f2f2f2;
}

.tm-vehicle-well {
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  min-height: 20px;
  padding-top: 8px;
  margin-bottom: 15px;
  color: #444444;
}

.tm-center-align {
  align-items: center;
  --bs-gutter-x: none;
  --bs-gutter-y: none;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.tm-modal-footer-bg {
  background-color: #f5f5f5;
}

.no-program {
  color: #8f8f8f;
}

.smartmiles-image-icon {
  width: 60px;
}
