.btn-group {
  width: 100%;
  label {
    min-width: 8rem;
  }
}

input[type='text'],
input[type='number'],
input[type='tel'],
input[type='email'],
select {
  font-size: 16px !important; /* any smaller causes unpleasant zoom on iPhone */
  height: auto !important;
}

form,
.nw-container {
  h1 {
    font-weight: 300;
  }

  h3 {
    font-weight: 300;
    margin-top: 2rem;
    margin-bottom: 2.5rem;
  }

  h4 {
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 1.5rem;
  }

  h5 {
    font-weight: 900;
  }
}

form {
  label,
  legend {
    display: block;
    line-height: 1.4rem;
    color: #222;
    font-weight: bold;
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
    span {
      font-weight: normal;
      font-style: normal;
      color: #767676;
    }
    &.button {
      font-weight: normal;
    }

    @media screen and (max-width: 767.9px) {
      font-size: 1.3rem;
    }
  }

  .form-check-input {
    margin-top: 0.1rem !important;
  }

  .btn-group-toggle label:focus-within {
    outline: 1px dotted #aaccee;
    outline-offset: -4px;
  }
}

.form-group {
  margin-bottom: 1.35rem !important;
}

.form-inline label {
  justify-content: left !important;
}

input.custom-input {
  border: 1px solid #ced4da;
}

/* Default rules for chapstick inputs.
 */

.input-group-text {
  padding: 0.1em !important;
}

.ng-valid + .input-group-text {
  background-color: #00ff00;
  padding: 0 !important;
}

.ng-invalid.ng-touched + .input-group-text {
  background-color: #ff0000;
}

.ng-pending + .input-group-text {
  background-color: #ffff00;
}
