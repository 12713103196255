$color-accessible-gray: #767676; // Good to use against a white background
$color-black: #333;

.custom-accordion.accordion {
  width: 100%;
  background: #fff !important;

  .card {
    border: 1px solid #ccc !important;
    border-radius: 3px;
  }
  .card a {
    text-decoration: none;
  }
  .card-body {
    border: none;
  }
  .card-header {
    padding: 0.8em 1em;
  }

  button.btn.btn-link.collapsed.ng-star-inserted::before {
    padding: 0;
    margin: 0;
    border: 0;
    background-color: transparent;
  }

  button.btn.btn-link.ng-star-inserted::before {
    padding: 0;
    margin: 0;
    border: 0;
    background-color: transparent;
  }

  button.btn.btn-link.collapsed.ng-star-inserted {
    padding: 0;
    margin: 0;
    border: 0;
    background-color: transparent;
  }

  button.btn.btn-link.ng-star-inserted {
    padding: 0;
    margin: 0;
    border: 0;
    background-color: transparent;
  }


  .accordion-title {
    color: $color-black;
    display: flex;
    align-items: center;
    background: #fff;
    font-size: 1.3em;
    padding-right: 50px;
    position: relative;
  }

  .accordion-title .nw-icon {
    color: $color-black;
    margin-right: 15px;
    width: 50px;
    height: 50px;
  }

  i.nw-icon.proceed {
    height: 30px;
    width: 30px;
    top: -6px;
    position: relative;
    right: 0;
    margin-right: 0;
  }
  .nw-data-view:after {
    display: none;
  }
  .nw-data-view {
    padding: 0px 10px;
  }
  .title-container span {
    color: $color-black;
  }
  .title-container .title-subtitle {
    color: $color-accessible-gray;
  }
  .title-container .position-right {
    color: $color-black;
    margin-left: 10px;
  }

  button.btn.btn-link {
    width: 100%;
  }

  button.btn-link:not(.collapsed) .accordion-title:after {
    top: 50%;
    margin-top: -10px;
    border-bottom-width: 7px;
    border-bottom-style: solid;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid transparent;
  }
  
  button.btn-link:hover,
  button.btn-link:focus,
  button.btn-link.focus {
    text-decoration: none;
    border-color: #0080ff;
  }
}
.accordion-button:not(.collapsed) {
  background-color: #fff;
}
